import React from "react";
import "./Content.css";
import book2 from "../assets/images/BOOK COLOR.png";
import book from "../assets/images/BOOK.png";

export const Content = () => {
  return (
    <div className=" bg-[#FFF1DB]">
      <div className="container mx-auto my-4">
        <h4 className="py-5">Latest From QuickBooksInternational Blog</h4>
        <div className="grid grid-cols-3 gap-4 mb-5" id="slide">
          <img className="" src={book2} alt="book5"></img>
          <img className="" src={book2} alt="book6"></img>
          <img className="" src={book2} alt="bok7"></img>
        </div>
      </div>
      <hr className="py-9" />
      <h4 className="py-9">Our Latest Books in 2024</h4>
      <div className="grid grid-cols-4 gap-4 ms-8" id="slide">
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="" src={book} alt="Book 1"  />
        </a>
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="" src={book} alt="Book 2" />
        </a>
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="" src={book} alt="Book 3" />
        </a>
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="" src={book} alt="Book 4"/>
        </a>
      </div>
    </div>
  );
};
